 /deep/ .demo-ruleForm .el-table th.el-table__cell {
  padding: 0;
}
 /deep/ .el-table__empty-block {
  min-height: 300px;
}
.labelTitle {
  line-height: 32px;
}
.labelTitle .redColor {
  color: red;
}
 /deep/ .el-cascader-panel:first-child .el-checkbox {
  display: none!important;
}
.showtitle {
  color: #f56c6c;
  font-size: 12px;
  line-height: 1;
  padding-top: 4px;
  position: relative;
  top: 100%;
  left: 0;
}
